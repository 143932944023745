/* Media screen below 992px  */

@media only screen and (max-width: 992px) {
  .ant-card-bordered .ant-card-cover {
    min-height: 130px !important;
  }
  .change-password-card .title-primary,
  .forgot-password-card .title-primary,
  .login-card .title-primary,
  .register-card .title-primary {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
    text-align: left !important;
    width: 100% !important;
  }
  .title-secondary {
    padding-left: 24px !important;
    text-align: left !important;
  }
  .title-primary {
    font-size: 30px !important;
    text-align: left !important;
    padding-left: 24px !important;
  }
  .forgot-password-card .title-secondary,
  .login-card .title-secondary,
  .register-card .title-secondary {
    margin-top: 20px !important;
  }
  .input-primary,
  .ant-form-item-has-error .input-primary,
  .ant-form-item-has-error .input-primary:hover,
  .input-primary:hover {
    font-size: 16px !important;
    padding: 0 20px !important;
    margin-bottom: 0 !important;
  }
  .ant-form-item,
  .ant-form-item-control {
    margin-bottom: 15px !important;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    /* margin: 10px 0 !important; */
  }
  .change-password-form-fields-wrapper,
  .forgot-password-form-fields-wrapper,
  .login-form-fields-wrapper,
  .register-form-fields-wrapper {
    padding: 0 25px !important;
  }
  .already-registered-text {
    margin-top: 2px !important;
  }
  .back-to-login-text {
    display: none;
  }
  .main-header {
    margin: 0;
    padding: 16px 0 0 0;
    left: 0;
    position: relative;
    /* height: 50px;     */
    display: none;
    width: 100%;
  }
  .main-header .brand-name {
    padding: 0 0 0 26px;
    margin: 0;
    height: 20px;
  }
  .login-card .title-secondary {
    margin-bottom: 0;
  }
  .login-card .title-primary {
    margin-top: 0 !important;
    margin-bottom: 73px !important;
  }
  .menu-togller {
    margin: 0 !important;
    text-align: right !important;
    justify-content: flex-end !important;
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    padding-right: 20px !important;
  }
  .main-content {
    /* margin-top: 0 !important; */
  }
  .profile-banner {
    padding: 40px 30px !important;
    width: 80% !important;
  }
  .hide-on-mobile {
    display: none !important;
  }
  /* hide main header on mobile  */
  .show-on-mobile {
    display: block !important;
  }
  h1.show-on-mobile {
    display: flex !important;
    width: 100%;
    justify-content: flex-end;
    padding-right: 20%;
    position: relative;
    align-items: center;
  }
  h1.show-on-mobile button.back-icon-absolute {
    position: absolute;
    left: 0;
    z-index: 1;
  }
  .menu-icon-toggler {
    display: none !important;
    padding-right: 15px;
    margin-bottom: 0;
  }
  .profile-form-fields-wrapper,
  .profile {
    width: 100% !important;
    padding: 0px 24px !important;
  }
}

.mobile-menu-trigger {
  margin-left: 10px;
}

@media only screen and (max-width: 600px) {
  .ant-card-bordered .ant-card-cover {
    min-height: 80px !important;
  }
}

@media only screen and (min-width: 767px) {
  .catalog-form-fields-wrapper {
    padding: 30px !important;
  }
  .catalog-form-fields-wrapper form#catalog-search-form {
    margin: 0 !important;
  }
  .anticon.anticon-menu.mobile-menu-trigger.drawer-trigger {
    display: none !important;
  }
  form#catalog-search-form {
    position: relative;
    margin: 30px;
  }
  form#catalog-search-form input {
    padding-left: 2% !important;
    min-height: 45px !important;
  }
  form#catalog-search-form span {
    position: absolute;
    top: 15%;
    z-index: 10;
    left: 2%;
    font-size: 25px;
    color: #797979;
  }
  form#catalog-search-form span.send {
    position: unset;
    /* top: 10%; */
    /* z-index: 10; */
    /* right: 10%; */
    cursor: pointer;
    /* left: unset; */
    font-size: 25px;
    color: #797979;
  }
  form#catalog-search-form .ant-row.ant-form-item.submit-btn {
    position: absolute;
    top: 0%;
    right: 0;
  }
  form#catalog-search-form .ant-row.ant-form-item.submit-btn button {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

@media only screen and (max-width: 767px) {
  .ant-dropdown {
    top: 70px !important;
  }
  .menu-drawer-mobile .ant-drawer-content-wrapper {
    width: 65% !important;
  }
  .main-content {
    margin-top: 0;
  }
  .main-footer {
    position: unset !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .main-footer .text-right {
    text-align: center !important;
  }
  .main-header .ant-row {
    height: auto !important;
  }
  #catalog-search-form .ant-row.ant-form-item {
    margin-bottom: 0 !important;
  }
  form#catalog-search-form {
    position: relative;
    width: 95%;
    margin: 20px auto 0 auto;
  }
  .catalog-form-fields-wrapper {
    padding: 0 !important;
  }
  form#catalog-search-form input {
    padding-left: 2% !important;
    min-height: 35px !important;
  }
  form#catalog-search-form span {
    position: absolute;
    top: 10%;
    z-index: 10;
    left: 2%;
    font-size: 25px;
    color: #797979;
  }
  form#catalog-search-form span.send {
    position: unset;
    /* top: 10%; */
    /* z-index: 10; */
    /* right: 10%; */
    cursor: pointer;
    /* left: unset; */
    font-size: 25px;
    color: #797979;
  }
  form#catalog-search-form .ant-row.ant-form-item.submit-btn {
    position: absolute;
    top: 0%;
    right: 0;
  }
  form#catalog-search-form .ant-row.ant-form-item.submit-btn button {
    background-color: transparent !important;
    border-color: transparent !important;
  }
  .drawer-menu {
    background: transparent;
    border: none;
    margin-top: 20px;
  }
  .drawer-menu li span a,
  .drawer-menu li span {
    color: #fff !important;
  }
  .ant-drawer-body {
    background-color: #5b7d89;
  }
  .mobile-menu-trigger {
    display: none;
  }
  .mobile-padding-left {
    padding-left: 10% !important;
  }
  .mobile-padding-left span {
    color: #fff !important;
  }
  .mobile-menu-trigger.drawer-trigger {
    display: flex;
    color: #fff;
  }
  .anticon.anticon-close {
    color: #fff !important;
  }
  .main-header {
    background-color: #5b7d89 !important;
    height: min-content !important;
    padding-top: 0 !important;
  }
  .main-left-navigation {
    display: none !important;
  }
  .ant-card-bordered .ant-card-cover {
    min-height: 100px !important;
  }
  .mobile-menu.ant-layout-sider-collapsed.main-left-navigation {
    width: 0 !important;
    padding: 0 !important;
    flex: unset !important;
    min-width: 0 !important;
    visibility: hidden;
  }
  .profile .songs-list li span {
    margin-left: 20px !important;
  }
  .profile .songs-list li .play-icon {
    margin-left: 0 !important;
  }
  .profile .songs-list li span:nth-child(4) {
    right: 15px !important;
  }
  .profile-banner {
    width: 90% !important;
    height: auto !important;
  }
  .change-password-card,
  .forgot-password-card,
  .login-card,
  .register-card {
    /* top: 20px !important; */
  }
  h1.forgot-password {
    width: 50% !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .subscription-card {
    margin-bottom: 20px;
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
      0 5px 12px 4px rgb(0 0 0 / 9%);
  }
  .subscription-card button:last-child {
    margin-top: 10px;
  }
  .billing-card {
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
      0 5px 12px 4px rgb(0 0 0 / 9%);
  }
  label.ant-checkbox-wrapper.checbox-wrapper:first-child {
    margin-top: 40px;
  }
  .catalog-wrapper .ant-card {
    min-height: 150px !important;
    max-height: 200px !important;
  }
  .menu-drawer-mobile .ant-drawer-content-wrapper {
    width: 90% !important;
  }
  .catalog-wrapper .ant-card {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
  }
  .catalog-wrapper .ant-card .ant-card-body {
    display: flex;
    height: 100%;
    /* justify-content: center; */
    align-items: center;
    padding: 5px;
  }
  .ant-card-meta-title {
    white-space: normal !important;
    font-size: 12px;
  }
  .mobile-padding-left {
    padding-left: 10% !important;
  }
  .mobile-menu.false {
    width: 0 !important;
    padding: 0 !important;
    flex: unset !important;
    min-width: 0 !important;
    visibility: hidden;
  }
  .mobile-menu.ant-layout-sider-collapsed.main-left-navigation.true
    ul
    li
    span.ant-menu-title-content {
    opacity: 1 !important;
  }
  .mobile-menu.ant-layout-sider-collapsed.main-left-navigation.true {
    width: auto !important;
    /* padding: auto !important; */
    flex: 0 0 80px !important;
    min-width: unset !important;
    visibility: visible !important;
    max-width: unset !important;
  }
  .profile .gallery .gallery-img-wrapper img {
    width: 100% !important;
  }
  h1.show-on-mobile {
    padding-right: 10% !important;
  }
}

@media only screen and (min-width: 992px) {
  .hide-on-mobile {
    display: flex !important;
  }
  .show-on-mobile {
    display: none !important;
  }
  .main-header {
    display: none;
  }
}
.register-form-fields-wrapper {
  padding-bottom: 100px !important;
}

@media only screen and (max-width: 1200px) {
  .change-password-form-fields-wrapper,
  .forgot-password-form-fields-wrapper,
  .login-form-fields-wrapper,
  .register-form-fields-wrapper {
    padding: 0 15px 100px 15px !important;
    margin-top: 30px;
  }
}

/* @media only screen and (max-width:992px) and (min-width:575px)
{
  .menu-togller {
    margin-left: 8%;
  }
} */

@media only screen and (max-height: 900px) {
  p.versionInfo {
    position: unset !important;
  }
}
