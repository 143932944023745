@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
:root {
    --border-color: rgba(15, 15, 15, 0.16);
    --primary-background-color: #ffffff;
}

html,
body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: "Montserrat", sans-serif !important;
}

* {
    box-sizing: border-box !important;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.ant-layout.ant-layout-has-sider {
    height: auto;
}

.ant-layout {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
}

.main-content {
    max-height: calc(100vh - 100px);
    background-color: #ffffff;
    background-color: var(--primary-background-color);
    margin-top: 100px;
}

.main-content-public {
    /* max-height: 100vh; */
    background-color: #ffffff;
    background-color: var(--primary-background-color);
    /* overflow-y   : scroll; */
    /* overflow-x   : hidden; */
}

.poster_bg {
    display: flex;
    min-height: 200px;
    border: 1px dashed #dbd6ae;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
}

.poster_bg img {
    width: 200px;
}

.poster_bg .anticon-upload {
    position: absolute;
    z-index: 10;
}

.poster_bg .anticon-edit {
    position: absolute;
    z-index: 10;
}

.poster_bg svg {
    font-size: 20px;
    color: #dbd6ae;
    cursor: pointer;
}

.main-header {
    background-color: #ffffff;
    background-color: var(--primary-background-color);
    border-bottom: 1px rgba(15, 15, 15, 0.16) solid;
    border-bottom: 1px var(--border-color) solid;
    position: unset;
    width: 100%;
    background: white;
    z-index: 1000;
    box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 10%);
    z-index: 1;
    height: 80px;
    border: none;
    padding: 0 60px 0 00px;
    left: 262px;
}

.main-header h1 {
    margin: 0;
    padding: 0;
}

@media (min-height: 900px) {
    .main-footer {
        position: absolute !important;
    }
}

.main-footer {
    width: 100%;
    background-color: #ffffff;
    background-color: var(--primary-background-color);
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 0 0 50px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.main-inner-content {
    min-height: 600px;
}

.btn-primary {
    /* padding: 12px 30px; */
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    height: auto;
    background-color: #dbd6ae;
    color: #000;
    border-radius: 5px;
    border: 1px rgba(15, 15, 15, 0.16) solid;
    border: 1px var(--border-color) solid;
}

.btn-primary:focus,
.btn-primary:active,
.btn-primary:hover {
    border: 1px #000 solid;
    background-color: #000;
    color: #dbd6ae;
}

.btn-secondary {
    /* padding: 20px; */
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    height: 64px;
    background-color: #ffffff;
    color: #0f0f0f;
    border-radius: 100px;
    font-family: "Montserrat", sans-serif;
    border: 1px rgba(15, 15, 15, 0.16) solid;
    border: 1px var(--border-color) solid;
}

.btn-secondary:hover {
    border: 1px #0f0f0f solid;
    background-color: #ffffff;
    color: #0f0f0f;
}

.change-password-card,
.forgot-password-card,
.login-card,
.register-card {
    max-width: 581px;
    min-height: auto;
    top: 92px;
    border: 1px rgba(15, 15, 15, 0.16) solid;
    border: 1px var(--border-color) solid;
    margin: 0 auto;
    padding: 60px 0;
}

.login-card .logo img,
.register-card .logo img {
    width: 200px;
    height: 200px;
}

.change-password-card .ant-card-body,
.forgot-password-card .ant-card-body,
.login-card .ant-card-body,
.register-card .ant-card-body {
    padding: 0;
}

.title-primary {
    font-size: 24px;
    font-weight: 400;
    color: #0f0f0f;
}

.title-secondary {
    font-size: 16px;
    font-weight: 400;
    color: rgba(15, 15, 15, 0.16);
}

.ant-select .ant-select-selector,
.ant-select .ant-select-selector:hover,
.ant-select .ant-select-selector:focus,
.ant-select .ant-select-selector:active {
    border: none !important;
    padding: 0 !important;
    box-shadow: none !important;
    outline: none !important;
}

.input-primary,
.ant-form-item-has-error .input-primary,
.ant-form-item-has-error .input-primary:hover,
.input-primary:hover,
.ant-select,
.ant-select:hover {
    width: 100%;
    min-height: 45px;
    padding: 5px 18px;
    background-color: #ffffff;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.5px;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border: 1px rgba(15, 15, 15, 0.16) solid;
    border: 1px var(--border-color) solid;
    border-radius: 5px;
}

.catalog_detail img {
    width: 100%;
}

#login-form_password.input-primary,
.input-primary.ant-input:focus,
.input-primary.ant-input-focused {
    box-shadow: none;
}

.ant-form-item,
.ant-form-item-control {
    margin-top: 0;
    /* margin-bottom: 25px !important; */
}

.ant-form-item {
    padding: 0;
    /* height    : 64px; */
    margin-top: 0;
    margin-bottom: 16px;
}

.ant-form-item.h-auto {
    height: auto;
}

.change-password-card .title-primary,
.forgot-password-card .title-primary,
.login-card .title-primary,
.register-card .title-primary {
    margin-left: 50px;
}

.change-password-form-fields-wrapper,
.forgot-password-form-fields-wrapper,
.login-form-fields-wrapper,
.register-form-fields-wrapper {
    padding: 0 80px;
    margin-top: 30px;
}

.back-to-login-text,
.not-registered-text,
.already-registered-text {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    font-weight: 400;
    color: #0f0f0f;
}

.back-to-login-text a,
.not-registered-text a,
.already-registered-text a {
    color: #0f0f0f;
    font-weight: 700;
}

.change-password-card #change-password-form_confirm,
.change-password-card #change-password-form_password,
.login-card #login-form_password,
.register-card #register-form_password {
    background-color: transparent;
}

.link-primary:hover {
    color: #dbd6ae;
}

.link-primary {
    color: #0f0f0f;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.5px;
}

.login-card .link-primary {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
}

.not-registered-text {
    margin-top: 57px;
}

.ant-btn {
    height: auto !important;
    min-height: 32px !important;
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
    background-color: #dbd6ae !important;
    color: #000;
}

.change-password-card .title-primary,
.forgot-password-card .title-primary {
    width: 337px;
    margin: 0 auto;
    margin-top: 173px;
    margin-bottom: 95px;
}

.ant-dropdown-menu {
    background-color: #5b7d89 !important;
    padding: 0 !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    color: #fff !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    color: #5b7d89 !important;
    /* font-weight: bold !important; */
}

.main-left-navigation .ant-menu .ant-menu-item:hover,
.main-left-navigation .ant-menu .ant-menu-item.ant-menu-active:hover {
    background-color: #dbd6ae !important;
}

.main-left-navigation .ant-menu .ant-menu-item.ant-menu-active .ant-menu-item-icon svg {
    color: #000 !important;
}

.main-left-navigation.ant-layout-sider-collapsed ul.ant-menu-inline-collapsed li.ant-menu-item .ant-menu-item-icon {
    margin-left: 15px;
}

.ant-tooltip-arrow-content {
    background-color: #dbd6ae !important;
}

.ant-tooltip-inner,
.ant-tooltip-inner a {
    background-color: #dbd6ae !important;
    color: #000 !important;
}

.ant-tooltip-inner .ant-btn-link {
    color: #000 !important;
}

.main-left-navigation.ant-layout-sider-collapsed p.versionInfo {
    font-size: 10px !important;
}

.main-left-navigation.ant-layout-sider-collapsed .brand-name {
    height: 50px;
    margin-top: 20px;
}

button {
    white-space: normal !important;
}

.main-left-navigation .ant-menu .ant-menu-item:hover a,
.main-left-navigation .ant-menu .ant-menu-item:hover button,
.main-left-navigation .ant-menu .ant-menu-item:hover button span {
    color: rgba(42, 42, 52, 1) !important;
    font-weight: normal !important;
}

.main-left-navigation .ant-menu .ant-menu-item a {
    color: rgba(42, 42, 52, 0.4) !important;
    font-weight: normal !important;
}

.main-left-navigation .ant-menu,
.main-left-navigation {
    background-color: #5b7d89 !important;
    border-color: #5b7d89 !important;
    min-height: 100vh !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.main-left-navigation .ant-menu .ant-menu-item a,
.main-left-navigation .ant-menu .anticon svg,
.main-left-navigation .ant-menu .ant-menu-item:last-child span.ant-menu-title-content button {
    color: #fff !important;
}

.box {
    width: 100px;
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, .05);
    height: 100px;
    position: relative;
}

.box:hover {
    transition: 0.3s all;
}


/* .box:hover::before {
  position: absolute;
  left: 0;
  z-index: 1;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  width: 100%;
  height: 100%;
} */

.box img {
    width: 100%;
    object-fit: scale-down;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}


/* .box:hover span {
  display: block !important;
} */

.box-wrapper {
    display: inline-flex;
    justify-content: center;
}

.box-wrapper span {
    /* object-fit: cover; */
    /* top: 50%; */
    font-size: 30px;
    /* display  : none; */
    cursor: pointer;
    font-weight: bold;
    /* transform: translate(-50%, -50%); */
    z-index: 10;
    color: #dbd6ae;
    /* left     : 50%; */
    /* position : absolute; */
}

.main-left-navigation .ant-menu .ant-menu-item:last-child span.ant-menu-title-content button {
    display: inline-block;
    position: unset;
    height: auto;
    margin-top: 0;
    margin-left: 0;
    color: #fff !important;
    font-weight: normal;
}

p.versionInfo {
    position: absolute;
    bottom: 20px;
    left: 10px;
    width: 80%;
    text-align: center;
}

.back-to-login-text {
    margin-top: 57px;
}

.forgot-password-card .title-secondary,
.login-card .title-secondary,
.register-card .title-secondary {
    display: block;
    margin-top: 59px;
}

.already-registered-text {
    margin-top: 47px;
}

.change-password-card .title-primary {
    width: 337px;
    margin: 0 auto;
    margin-top: 75px;
    margin-bottom: 24px;
}

.change-password-help-text {
    margin-bottom: 129px;
}

.main-left-navigation {
    background-color: #ffffff;
    border-right: 1px rgba(42, 42, 52, 0.12) solid;
    max-width: 262px;
}


/* .main-left-navigation {
    min-width: unset !important;
    width: auto !important;
    transition: .5s all;
    max-width: unset !important;
}

.main-left-navigation.ant-layout-sider-collapsed {
    min-width: auto !important;
    width: auto !important;
    max-width: 90px !important;
} */

.main-header .brand-name,
.main-left-navigation .brand-name,
.brand-name {
    padding: 0 0 0 47px;
    position: relative;
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
    font-style: normal;
    color: #0f0f0f;
    height: 100px;
    display: flex;
    align-items: center;
}

.main-header .brand-name img,
.main-left-navigation .brand-name img,
.brand-name img {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
}

.main-header .brand-name {
    padding: 0 0 0 137px;
}

.main-left-navigation .ant-menu {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 21px;
    color: rgba(42, 42, 52, 0.4);
}

.main-left-navigation .ant-menu {
    padding: 0;
    margin: 0;
}

.brand-name {
    padding-left: 0 !important;
    text-align: center !important;
    margin-bottom: 30px;
}

.main-left-navigation .ant-menu .ant-menu-item {
    padding: 0;
    margin: 5px 0;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.83px;
    height: auto;
    display: block;
    overflow: hidden;
    align-items: center;
}

.main-left-navigation .ant-menu .anticon {
    padding: 0 10px 0 15px !important;
    line-height: unset !important;
}

.main-left-navigation .ant-menu .ant-menu-item a {
    color: rgba(42, 42, 52, 0.4);
}

.main-left-navigation .ant-menu .anticon {
    padding: 0 29px 0 47px;
    margin: 0;
}

.main-left-navigation .ant-menu span:nth-child(2) {
    padding: 0;
    margin: 0;
}

.main-left-navigation .ant-menu-item-active,
.main-left-navigation .ant-menu-item-selected {
    background-color: transparent !important;
    position: relative;
    color: #0f0f0f;
    overflow: visible;
}

.main-left-navigation .ant-menu-vertical .ant-menu-item::after {
    border-right: none;
}

.main-left-navigation .ant-menu-item-active::after,
.main-left-navigation .ant-menu-item-selected::after {
    content: "";
    position: absolute;
    right: -2px;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: #0f0f0f;
    opacity: 1;
    z-index: 1;
    -webkit-transform: unset;
            transform: unset;
    border: none !important;
}

.main-left-navigation .logout-btn {
    height: 36px;
    display: flex;
    align-items: center;
    color: #0f0f0f;
    margin-left: 47px;
    padding: 0;
    margin-top: 364px;
    position: absolute;
    bottom: 43px;
}

.ant-layout {
    background-color: #ffffff;
}

.main-left-navigation .ant-menu-item-active a,
.main-left-navigation .ant-menu-item-selected a {
    color: #0f0f0f !important;
}

.current-page-title {
    font-style: normal;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    font-family: "Montserrat", sans-serif;
}

.flex-items-center {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.main-header .ant-row {
    height: 100%;
}

.main-header .user-display-name {
    font-style: normal;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    font-family: "Montserrat", sans-serif;
    color: #0f0f0f;
}

.main-header .user-avatar {
    margin-right: 16px;
}

.main-header .user-avatar .anticon {
    color: rgba(35, 11, 52, 1);
}

.main-header .user-menu-wrapper {
    justify-content: end;
}

.main-header .user-menu-wrapper .user-menu {
    right: 0;
}

.profile-banner {
    width: 550px;
    height: 480px;
    background-color: rgba(15, 15, 15, 0.1);
    padding: 62px;
    margin: 100px auto 0 auto;
}

.profile-banner h2 {
    font-size: 48px;
    font-family: "DM Serif Display", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 66px;
    display: block;
    margin: 0;
}

.profile-banner p {
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
    color: rgba(15, 15, 15, 0.6);
    margin: 0;
}

.profile-banner .btn-primary {
    height: 60px;
    padding: 18px 34px;
    margin-top: 43px;
}

.app-dropzone {
    min-height: 205px;
    width: 100%;
    border-radius: 24px;
    background-color: rgba(15, 15, 15, 0.1);
    padding: 16px 24px;
    margin-bottom: 16px;
    display: inline-block;
}

.app-dropzone.app-dropzone-round,
.app-dropzone.app-dropzone-round .dropzone {
    border-radius: 100%;
    margin: 0;
    padding: 0;
    min-height: 92px;
    max-height: 92px;
    min-width: 92px;
    position: relative;
    overflow: hidden;
    max-width: 92px;
}

.app-dropzone.app-dropzone-round .dropzone .image-preview,
.app-dropzone.app-dropzone-round .dropzone .image-preview .image-preview-inner,
.app-dropzone.app-dropzone-round .dropzone .image-preview .image-preview-inner .img-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.app-dropzone.app-dropzone-round .dropzone .image-preview .image-preview-inner .img-thumb img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.app-dropzone .app-dropzone-label {
    color: rgba(15, 15, 15, 0.6);
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
}

.app-dropzone .dropzone {
    margin-top: 12px;
    min-height: 128px;
    background-color: rgba(15, 15, 15, 0.1);
    border: 1px dashed rgba(15, 15, 15, 0.24);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.app-dropzone .dropzone .anticon {
    display: inline-block;
    /* margin: 0 auto; */
}

.app-dropzone .dropzone .dropzone-help-content {
    display: inline-block;
    /* margin: 0 auto; */
}

.app-dropzone .dropzone .dropzone-help-content p {
    margin: 0;
}

.textarea-form-field {
    height: auto;
}

.textarea-primary,
.ant-form-item-has-error .textarea-primary,
.ant-form-item-has-error .textarea-primary:hover,
.textarea-primary:hover {
    border-radius: 24px;
    padding: 20px;
    background-color: rgba(15, 15, 15, 0.1);
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.5px;
    border: 1px rgba(15, 15, 15, 0.16) solid;
    border: 1px var(--border-color) solid;
}

.select-primary,
.ant-form-item-has-error .select-primary,
.ant-form-item-has-error .select-primary:hover,
.select-primary:hover {
    height: 64px;
    border-radius: 100px;
    background-color: rgba(15, 15, 15, 0.1);
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.5px;
    border: 1px rgba(15, 15, 15, 0.16) solid;
    border: 1px var(--border-color) solid;
}

.authenticated-header {
    display: block !important;
}

.select-primary .ant-select-selector {
    margin: 0;
    height: 64px !important;
    border-radius: 100px;
    padding: 14px !important;
    background-color: transparent !important;
    border: none !important;
}

.profile-avatar {
    display: flex;
    align-items: center;
    background-color: rgba(196, 196, 196, 1);
    margin: 0 auto;
    margin-bottom: 9px;
}

.profile-avatar .anticon {
    display: inline-block;
    margin: 0 auto !important;
    color: rgba(35, 11, 52, 1);
}

.profile-form-fields-wrapper {
    width: 780px;
    margin: 0 auto;
}

.profile-form-fields-wrapper .ant-form-item {
    margin-bottom: 25px;
}

.ant-popover .ant-popover-inner {
    border-radius: 20px;
}

.ant-popover * {
    font-family: "Montserrat", sans-serif;
}

.ant-popover .ant-btn-primary {
    padding: 10px;
    text-align: center;
    line-height: 18px;
    font-size: 16px;
    height: 64px;
    background-color: #dbd6ae !important;
    color: #000 !important;
    border-radius: 100px;
    font-family: "Montserrat", sans-serif;
    border: 1px rgba(15, 15, 15, 0.16) solid;
    border: 1px var(--border-color) solid;
}

.ant-popover .ant-btn-primary:hover {
    padding: 10px;
    text-align: center;
    line-height: 18px;
    font-size: 16px;
    /* height       : 64px; */
    background-color: #000 !important;
    color: #dbd6ae !important;
    border-radius: 100px;
    font-family: "Montserrat", sans-serif;
    border: 1px rgba(15, 15, 15, 0.16) solid;
    border: 1px var(--border-color) solid;
}

.ant-popover .ant-btn-primary:focus,
.ant-popover .ant-btn-primary:active,
.ant-popover .ant-btn-primary:hover {
    border: 1px #0f0f0f solid;
    background-color: #0f0f0f;
    color: #ffffff;
}

.ant-popover .ant-btn {
    padding: 10px;
    text-align: center;
    line-height: 18px;
    font-size: 16px;
    height: 40px;
    background-color: #000;
    color: #dbd6ae;
    border-radius: 100px;
    font-family: "Montserrat", sans-serif;
    border: 1px rgba(15, 15, 15, 0.16) solid;
    border: 1px var(--border-color) solid;
}

.ant-popover .ant-btn:hover {
    border: 1px #0f0f0f solid;
    background-color: #ffffff;
    color: #0f0f0f;
}

.multi-input .input-primary {
    border: 1px rgba(15, 15, 15, 0.16) dashed !important;
    border: 1px var(--border-color) dashed !important;
    background-color: rgba(231, 231, 231, 0.4) !important;
}

.multi-input .last-input {
    margin-bottom: 0 !important;
}

.multi-input .btn-link {
    color: rgba(15, 15, 15, 1);
    margin: 12px 0 22px 0;
    padding: 0;
}

.image-preview {
    width: 100%;
    height: auto;
}

.image-preview .image-preview-inner {
    /* padding: 5px; */
    max-height: 145px;
    height: 145px;
    overflow-y: hidden;
    position: relative;
}

.image-preview .image-preview-inner.single-file {
    /* padding: 5px; */
}

.image-preview .image-preview-inner.multi-file {
    /* padding: 5px; */
    overflow-x: scroll;
    white-space: nowrap !important;
}

.image-preview .image-preview-inner.multi-file .img-thumb {
    /* display: block;
  float: left;
  margin-right: 13px;
  height: 120px !important;
  width: 120px !important; */
}

.image-preview .image-preview-inner .image-preview-img-small {
    /* border-radius: 10px;
  height: 119px;
  width: 119px; */
}

.image-preview .image-preview-inner .image-preview-img-large {
    border-radius: 10px;
    width: 100%;
    max-height: 120px;
}

.image-preview-inner.multi-file .img-thumb {
    width: 120px;
    height: 120px;
    position: relative;
    display: inline-block;
    margin-right: 13px;
}

.image-preview .image-preview-inner .image-preview-img-small {
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.img-thumb .ant-btn {
    border-radius: 100%;
    background-color: rgba(15, 15, 15, 1);
    color: #ffffff;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
    height: 30px;
    width: 30px;
    padding: 0;
    border: none;
}

.select-primary.ant-select-multiple .ant-select-selector {
    padding: 5px 8px !important;
}

.select-primary.ant-select-multiple .ant-select-selector {
    overflow: hidden;
}

.select-primary.ant-select-multiple .ant-select-selection-overflow {
    flex-wrap: nowrap;
    overflow: hidden;
}

.select-primary.ant-select-multiple .ant-select-selection-overflow-item .ant-select-selection-item {
    padding: 7px 21px;
    height: 41px;
    margin-right: 5px;
    text-align: center;
    font-size: 16px;
    background-color: #0f0f0f;
    color: #ffffff;
    border-radius: 100px;
    font-family: "Montserrat", sans-serif;
    border: 1px rgba(15, 15, 15, 0.16) solid;
    border: 1px var(--border-color) solid;
}

.select-primary.ant-select-multiple .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-content {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.select-primary.ant-select-multiple .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-remove {
    color: #ffffff;
}

.profile {
    width: 773px;
    margin: 0 auto;
}

.profile .avatar-image {
    display: inline-block;
    padding: 25px;
    margin: 0 auto;
}

.profile .avatar-image img {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    border: rgba(255, 255, 255, 1) 8px solid;
    box-shadow: 0px 6px 24px rgba(68, 68, 68, 0.24);
}

.profile .fullname {
    font-size: 20px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    line-height: 30px;
    margin: 0;
    padding: 0;
}

.profile .genres {
    font-size: 16px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    line-height: 25px;
    margin-bottom: 25px;
    padding: 0;
    letter-spacing: 0.5px;
    color: rgba(15, 15, 15, 0.6);
    text-transform: capitalize;
    display: block;
}

.profile .bio {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    display: inline-block;
    margin-bottom: 8px;
}

.profile .songs-list {
    margin: 0;
    padding: 0;
}

.profile .songs-list li {
    height: 48px;
    border: 1px solid rgba(33, 34, 45, 0.08);
    border-radius: 8px;
    list-style: none;
    margin-bottom: 9px;
    padding: 8px 24px;
    position: relative;
}

.profile .songs-list li span {
    display: block;
    margin-left: 53px;
}

.profile .songs-list li span:nth-child(2) {
    font-weight: 700;
    font-size: 12px;
    line-height: 15.62px;
    font-style: normal;
    font-family: "Montserrat", sans-serif;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 70%;
}

.profile .songs-list li span:nth-child(3) {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    font-style: normal;
    font-family: "Montserrat", sans-serif;
    color: rgba(33, 34, 45, 0.4);
}

.profile .songs-list li span:nth-child(4) {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    font-style: normal;
    font-family: "Montserrat", sans-serif;
    color: rgba(33, 34, 45, 0.4);
    position: absolute;
    right: 32px;
    top: 16px;
}

.profile .songs-list li .play-icon {
    position: absolute;
    top: 13px;
    margin: 0;
    padding: 0;
}

.profile .gallery .gallery-img-wrapper {
    display: inline-block;
    margin: 0 auto;
    height: 139px;
    width: 100%;
    text-align: center;
    margin-bottom: 22px;
    align-items: center;
}

.profile .gallery .gallery-img-wrapper img {
    height: 139px;
    width: 166px;
}

.general-info .general-info-item {
    min-height: 72px;
    border: 1px solid rgba(15, 15, 15, 0.12);
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 12px;
}

.general-info .general-info-item span:nth-child(1) {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: rgba(15, 15, 15, 0.6);
    display: block;
}

.general-info .general-info-item span:nth-child(2) {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: rgba(15, 15, 15, 1);
    display: block;
    text-transform: capitalize;
}

.general-info .btn-primary {
    /* height: 48px; */
    padding: 13px 23px;
    font-size: 14px;
    line-height: 22px;
}

.profile-form-fields-wrapper .input-primary {
    font-weight: bold;
}

.profile-form-fields-wrapper .ant-select-selection-item {
    font-weight: bold;
}

.profile-form-fields-wrapper .ant-picker-input input {
    font-weight: bold;
}

.ant-layout-sider-children {
    width: 100%;
}

.ant-modal .ant-modal-content {
    border-radius: 20px;
}

.ant-modal .ant-modal-header {
    border-radius: 20px 20px 0 0;
    background-color: #0f0f0f;
}

.ant-modal .ant-modal-header .ant-modal-title {
    color: #ffffff;
    background-color: transparent;
}

.ant-modal .ant-modal-close-x .anticon.anticon-close.ant-modal-close-icon {
    color: #ffffff;
}

.radios-vertical {
    height: auto;
}

.radios-vertical .ant-radio-wrapper {
    display: inline-block;
    line-height: 30px;
    text-align: left;
    width: 100%;
}

.step-hidden {
    display: none;
}

.step-visible {
    display: block;
}

.artifact-wrapper,
.catalog-wrapper {
    padding: 20px;
}

.catalog-wrapper .ant-card {
    margin-bottom: 10px;
    min-height: 300px;
    max-height: 350px;
    overflow: hidden;
}

.search-form .catalog-form-fields-wrapper {
    padding-left: 0 !important;
}

#catalog-search-form input {
    border-top: 1px rgba(15, 15, 15, 0.16) solid !important;
    border-top: 1px var(--border-color) solid !important;
    border-bottom: 1px rgba(15, 15, 15, 0.16) solid !important;
    border-bottom: 1px var(--border-color) solid !important;
    border-right: 1px rgba(15, 15, 15, 0.16) solid !important;
    border-right: 1px var(--border-color) solid !important;
    border-left: 1px rgba(15, 15, 15, 0.16) solid !important;
    border-left: 1px var(--border-color) solid !important;
}

#catalog-search-form button {
    padding: 10px 30px !important;
}

.catalog-wrapper .ant-card.list-mode .ant-card-body {
    padding: 0px 24px !important;
}

.catalog-wrapper .ant-card.list-mode {
    display: flex;
    min-height: auto;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    padding: 10px;
}

.catalog-wrapper .ant-card.list-mode .ant-card-cover img {
    width: 100px;
    height: 100px;
}

.catalog-wrapper .ant-card.card-mode .ant-card-cover img {
    width: 100%;
    max-height: 200px;
}

.catalog-form-fields-wrapper {
    padding: 30px;
}

.catalog-form-fields-wrapper .poster-upload {
    display: inline-block;
    width: 100%;
}

.catalog-form-fields-wrapper .poster-upload .ant-upload {
    display: inline-block;
    width: 100%;
}

.catalog-form-fields-wrapper .poster {
    display: flex;
    height: 300px;
    width: 100%;
    padding: 5px;
    border: 1px dashed grey;
    border-radius: 5px;
    text-align: center;
    align-items: center;
}

.catalog-form-fields-wrapper .poster img {
    max-height: 100%;
    width: auto !important;
    margin: 0 auto;
}

.catalog-form-fields-wrapper .poster .anticon {
    font-size: 30px;
    color: #f53f61;
    margin: 0 auto !important;
}

@media only screen and (max-width: 450px) {
    .list-mode.ant-card-bordered .ant-card-cover {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
    .catalog-wrapper .ant-card.card-mode .ant-card-cover img {
        max-height: 100px !important;
    }
    .catalog-wrapper .ant-card.list-mode {
        display: flex !important;
        justify-content: space-evenly !important;
        flex-direction: row !important;
    }
    .catalog-wrapper .ant-card.list-mode .ant-card-cover img {
        width: 100px !important;
        position: unset !important;
        height: auto !important;
    }
}

.ant-drawer-body .user-display-name {
    font-style: normal;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    font-family: "Montserrat", sans-serif;
    color: #fff;
}

.ant-drawer-body .logout-btn {
    height: 36px;
    display: flex;
    align-items: center;
    color: #fff;
    /* margin-left: 47px; */
    padding: 0;
    /* margin-top : 364px; */
    /* position   : absolute; */
    /* bottom     : 43px; */
    margin-top: 0;
    margin-left: 15px;
}

.loginForm {
    width: 100%;
    background: #5b7d89;
    height: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loginForm .columns {
    width: 100% !important;
}

.change-password-card,
.forgot-password-card,
.login-card,
.register-card {
    max-width: 581px;
    min-height: auto;
    top: 0;
    border: 1px transparent solid;
    background-color: transparent;
    margin: 0 auto;
    padding: 60px 0;
}

.ant-form-item-explain.ant-form-item-explain-error,
.ant-checkbox-wrapper {
    color: #dbd6ae !important;
    /* font-weight: bold !important; */
}

.login-card .ant-radio-wrapper,
.register-card .ant-radio-wrapper {
    color: #dbd6ae !important;
    display: inline-flex;
}


/* .list-mode .ant-card-actions>li>span button {
    padding: 0px 5px !important;
    font-size: 10px !important;
    height: 20px;
} */

.ant-btn-primary {
    background-color: #dbd6ae !important;
    border-color: #dbd6ae !important;
    color: #000 !important;
}

.ant-btn-primary:hover {
    background-color: #000 !important;
    border-color: #000 !important;
    color: #dbd6ae !important;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background-color: #dbd6ae !important;
    border-color: #dbd6ae !important;
    color: #000 !important;
}

.ant-radio-button-wrapper {
    background-color: #000 !important;
    color: #dedede !important;
    border-color: #000 !important;
}

.list-mode .ant-card-actions {
    display: flex !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.ant-card-actions label.ant-radio-button-wrapper {
    background-color: #fff !important;
    border-color: #d9d9d9 !important;
}

.ant-card-actions label.ant-radio-button-wrapper span {
    color: #000 !important;
}

.login-card .ant-form-item-label>label,
.register-card .ant-form-item-label>label {
    color: #dbd6ae !important;
}

.ant-radio-button-wrapper.btn-primary {
    background-color: #000 !important;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #dbd6ae !important;
    border-color: #000 !important;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.btn-primary {
    background-color: #dbd6ae !important;
    border-color: #dbd6ae !important;
    color: #000 !important;
}

.ant-card-meta-title {
    white-space: normal !important;
}

.list-mode.ant-card-bordered .ant-card-cover {
    min-height: 100px;
    min-width: 100px;
}

.ant-card-bordered .ant-card-cover {
    min-height: 200px;
    position: relative;
    overflow: hidden;
}

.ant-card-bordered .ant-card-cover img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.image-holder {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.image-holder img {
    object-fit: contain;
    /* height : 100%; */
}

.public-header {
    background: #5b7d89;
}

.public-header-logo img {
    max-width: 50px;
    display: block;
    height: auto;
}

.public-header a.user-display-name.user-menu {
    color: #fff !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background: #5b7d89;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background: #dbd6ae;
    color: #0f0f0f;
}

.user-profile-picture {
    width: 100%;
    height: 150px;
    overflow: hidden;
    border-radius: 100%;
    text-align: center;
    /* position  : relative; */
}

.user-profile-picture img {
    width: 100%;
    height: 100%;
    /* transform: translate(-50%, -50%); */
    object-fit: contain;
    display: inline-block;
    /* top      : 50%;
    left      : 50%;
    position  : absolute; */
}

.user-profile-picture-upload {
    display: flex;
    width: 200px;
    height: 200px;
    border: 1px dashed #dbd6ae;
    border-radius: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.user-profile-picture-upload img {
    width: auto;
    height: 220px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: inline-block;
    top: 50%;
    object-fit: contain;
    left: 50%;
    position: absolute;
}

label.ant-radio-wrapper.checbox-wrapper {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    padding: 0 20px;
    margin-left: 0;
}

label.ant-radio-wrapper.checbox-wrapper span {
    display: flex;
    align-items: center;
}

label.ant-radio-wrapper.checbox-wrapper span img {
    width: 50px;
    height: 50px;
    margin: 0px 10px;
    object-fit: contain;
}

label.ant-radio-wrapper.checbox-wrapper:after {
    position: absolute;
    bottom: -15px;
    left: 2%;
    width: 95%;
    height: 2px;
    content: "";
    background: rgba(0, 0, 0, 0.1);
}

.audio-group-wrapper {
    width: 100%;
}

label.ant-radio-wrapper.checbox-wrapper i.dealer {
    display: inline-flex;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 20px;
    position: unset;
    right: 5%;
    font-weight: bold;
}

@media only screen and (max-width: 575px) {
    label.ant-radio-wrapper.checbox-wrapper i.dealer,
    .checbox-wrapper-item i.dealer {
        position: absolute !important;
    }
    .checbox-wrapper-item:first-child {
        margin-top: 20px !important;
    }
    .checbox-wrapper-item {
        padding: 10px 0 !important;
    }
    .checbox-wrapper-item .name {
        display: flex;
        width: 50%;
        word-wrap: break-word;
        align-items: center;
    }
}

.modal-content p {
    text-align: center;
    margin-bottom: 0;
}

.ant-modal-footer {
    display: flex;
    justify-content: space-between;
}

.checbox-wrapper-item {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    cursor: pointer;
    padding: 0 20px;
    margin-left: 0;
}

.checbox-wrapper-item::after {
    position: absolute;
    bottom: -15px;
    left: 2%;
    width: 95%;
    height: 2px;
    content: "";
    background: rgba(0, 0, 0, 0.1);
}

.checbox-wrapper-item img {
    width: 50px;
    height: 50px;
    margin: 0px 10px;
    object-fit: contain;
}

.checbox-wrapper-item .name i.dealer {
    display: inline-flex;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 20px;
    position: unset;
    right: 5%;
    font-weight: bold;
}

.delete-loader {
    position: absolute;
    top: 0;
}

.imagee-holder {
    width: 90px;
    height: 90px;
    display: inline-block;
    margin-right: 20px;
    position: relative;
}

.imagee-holder img {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    object-fit: contain;
    z-index: 1;
}

.maxHeightt {
    padding: 0;
}

.maxHeightt .maxHeight {
    max-height: 400px;
    overflow: auto;
    padding: 10px 25px;
}

.center {
    text-align: center;
}

.maxHeightt .ant-modal-footer {
    display: none;
}

.date-picker-item .ant-picker {
    min-height: 45px;
    border-left: none;
    border-radius: 5px;
    border-right: none;
    width: 100%;
    border-top: none;
}

span.anticon.anticon-delete {
    position: relative;
    z-index: 100;
    font-weight: bold;
    font-size: 25px;
    color: #dbd6ae;
}

.image-holderr {
    display: inline-flex;
    position: relative;
    justify-content: center;
    margin: 10px;
}

.image-holderr .anticon.anticon-delete {
    position: absolute;
    z-index: 100;
    font-weight: bold;
    font-size: 25px;
    color: #fff;
    top: 2%;
    background: rgba(0, 0, 0, .5);
    left: 2%;
    padding: 3px;
}
/* Media screen below 992px  */

@media only screen and (max-width: 992px) {
  .ant-card-bordered .ant-card-cover {
    min-height: 130px !important;
  }
  .change-password-card .title-primary,
  .forgot-password-card .title-primary,
  .login-card .title-primary,
  .register-card .title-primary {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
    text-align: left !important;
    width: 100% !important;
  }
  .title-secondary {
    padding-left: 24px !important;
    text-align: left !important;
  }
  .title-primary {
    font-size: 30px !important;
    text-align: left !important;
    padding-left: 24px !important;
  }
  .forgot-password-card .title-secondary,
  .login-card .title-secondary,
  .register-card .title-secondary {
    margin-top: 20px !important;
  }
  .input-primary,
  .ant-form-item-has-error .input-primary,
  .ant-form-item-has-error .input-primary:hover,
  .input-primary:hover {
    font-size: 16px !important;
    padding: 0 20px !important;
    margin-bottom: 0 !important;
  }
  .ant-form-item,
  .ant-form-item-control {
    margin-bottom: 15px !important;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    /* margin: 10px 0 !important; */
  }
  .change-password-form-fields-wrapper,
  .forgot-password-form-fields-wrapper,
  .login-form-fields-wrapper,
  .register-form-fields-wrapper {
    padding: 0 25px !important;
  }
  .already-registered-text {
    margin-top: 2px !important;
  }
  .back-to-login-text {
    display: none;
  }
  .main-header {
    margin: 0;
    padding: 16px 0 0 0;
    left: 0;
    position: relative;
    /* height: 50px;     */
    display: none;
    width: 100%;
  }
  .main-header .brand-name {
    padding: 0 0 0 26px;
    margin: 0;
    height: 20px;
  }
  .login-card .title-secondary {
    margin-bottom: 0;
  }
  .login-card .title-primary {
    margin-top: 0 !important;
    margin-bottom: 73px !important;
  }
  .menu-togller {
    margin: 0 !important;
    text-align: right !important;
    justify-content: flex-end !important;
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    padding-right: 20px !important;
  }
  .main-content {
    /* margin-top: 0 !important; */
  }
  .profile-banner {
    padding: 40px 30px !important;
    width: 80% !important;
  }
  .hide-on-mobile {
    display: none !important;
  }
  /* hide main header on mobile  */
  .show-on-mobile {
    display: block !important;
  }
  h1.show-on-mobile {
    display: flex !important;
    width: 100%;
    justify-content: flex-end;
    padding-right: 20%;
    position: relative;
    align-items: center;
  }
  h1.show-on-mobile button.back-icon-absolute {
    position: absolute;
    left: 0;
    z-index: 1;
  }
  .menu-icon-toggler {
    display: none !important;
    padding-right: 15px;
    margin-bottom: 0;
  }
  .profile-form-fields-wrapper,
  .profile {
    width: 100% !important;
    padding: 0px 24px !important;
  }
}

.mobile-menu-trigger {
  margin-left: 10px;
}

@media only screen and (max-width: 600px) {
  .ant-card-bordered .ant-card-cover {
    min-height: 80px !important;
  }
}

@media only screen and (min-width: 767px) {
  .catalog-form-fields-wrapper {
    padding: 30px !important;
  }
  .catalog-form-fields-wrapper form#catalog-search-form {
    margin: 0 !important;
  }
  .anticon.anticon-menu.mobile-menu-trigger.drawer-trigger {
    display: none !important;
  }
  form#catalog-search-form {
    position: relative;
    margin: 30px;
  }
  form#catalog-search-form input {
    padding-left: 2% !important;
    min-height: 45px !important;
  }
  form#catalog-search-form span {
    position: absolute;
    top: 15%;
    z-index: 10;
    left: 2%;
    font-size: 25px;
    color: #797979;
  }
  form#catalog-search-form span.send {
    position: unset;
    /* top: 10%; */
    /* z-index: 10; */
    /* right: 10%; */
    cursor: pointer;
    /* left: unset; */
    font-size: 25px;
    color: #797979;
  }
  form#catalog-search-form .ant-row.ant-form-item.submit-btn {
    position: absolute;
    top: 0%;
    right: 0;
  }
  form#catalog-search-form .ant-row.ant-form-item.submit-btn button {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

@media only screen and (max-width: 767px) {
  .ant-dropdown {
    top: 70px !important;
  }
  .menu-drawer-mobile .ant-drawer-content-wrapper {
    width: 65% !important;
  }
  .main-content {
    margin-top: 0;
  }
  .main-footer {
    position: unset !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .main-footer .text-right {
    text-align: center !important;
  }
  .main-header .ant-row {
    height: auto !important;
  }
  #catalog-search-form .ant-row.ant-form-item {
    margin-bottom: 0 !important;
  }
  form#catalog-search-form {
    position: relative;
    width: 95%;
    margin: 20px auto 0 auto;
  }
  .catalog-form-fields-wrapper {
    padding: 0 !important;
  }
  form#catalog-search-form input {
    padding-left: 2% !important;
    min-height: 35px !important;
  }
  form#catalog-search-form span {
    position: absolute;
    top: 10%;
    z-index: 10;
    left: 2%;
    font-size: 25px;
    color: #797979;
  }
  form#catalog-search-form span.send {
    position: unset;
    /* top: 10%; */
    /* z-index: 10; */
    /* right: 10%; */
    cursor: pointer;
    /* left: unset; */
    font-size: 25px;
    color: #797979;
  }
  form#catalog-search-form .ant-row.ant-form-item.submit-btn {
    position: absolute;
    top: 0%;
    right: 0;
  }
  form#catalog-search-form .ant-row.ant-form-item.submit-btn button {
    background-color: transparent !important;
    border-color: transparent !important;
  }
  .drawer-menu {
    background: transparent;
    border: none;
    margin-top: 20px;
  }
  .drawer-menu li span a,
  .drawer-menu li span {
    color: #fff !important;
  }
  .ant-drawer-body {
    background-color: #5b7d89;
  }
  .mobile-menu-trigger {
    display: none;
  }
  .mobile-padding-left {
    padding-left: 10% !important;
  }
  .mobile-padding-left span {
    color: #fff !important;
  }
  .mobile-menu-trigger.drawer-trigger {
    display: flex;
    color: #fff;
  }
  .anticon.anticon-close {
    color: #fff !important;
  }
  .main-header {
    background-color: #5b7d89 !important;
    height: -webkit-min-content !important;
    height: min-content !important;
    padding-top: 0 !important;
  }
  .main-left-navigation {
    display: none !important;
  }
  .ant-card-bordered .ant-card-cover {
    min-height: 100px !important;
  }
  .mobile-menu.ant-layout-sider-collapsed.main-left-navigation {
    width: 0 !important;
    padding: 0 !important;
    flex: unset !important;
    min-width: 0 !important;
    visibility: hidden;
  }
  .profile .songs-list li span {
    margin-left: 20px !important;
  }
  .profile .songs-list li .play-icon {
    margin-left: 0 !important;
  }
  .profile .songs-list li span:nth-child(4) {
    right: 15px !important;
  }
  .profile-banner {
    width: 90% !important;
    height: auto !important;
  }
  .change-password-card,
  .forgot-password-card,
  .login-card,
  .register-card {
    /* top: 20px !important; */
  }
  h1.forgot-password {
    width: 50% !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .subscription-card {
    margin-bottom: 20px;
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
      0 5px 12px 4px rgb(0 0 0 / 9%);
  }
  .subscription-card button:last-child {
    margin-top: 10px;
  }
  .billing-card {
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
      0 5px 12px 4px rgb(0 0 0 / 9%);
  }
  label.ant-checkbox-wrapper.checbox-wrapper:first-child {
    margin-top: 40px;
  }
  .catalog-wrapper .ant-card {
    min-height: 150px !important;
    max-height: 200px !important;
  }
  .menu-drawer-mobile .ant-drawer-content-wrapper {
    width: 90% !important;
  }
  .catalog-wrapper .ant-card {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
  }
  .catalog-wrapper .ant-card .ant-card-body {
    display: flex;
    height: 100%;
    /* justify-content: center; */
    align-items: center;
    padding: 5px;
  }
  .ant-card-meta-title {
    white-space: normal !important;
    font-size: 12px;
  }
  .mobile-padding-left {
    padding-left: 10% !important;
  }
  .mobile-menu.false {
    width: 0 !important;
    padding: 0 !important;
    flex: unset !important;
    min-width: 0 !important;
    visibility: hidden;
  }
  .mobile-menu.ant-layout-sider-collapsed.main-left-navigation.true
    ul
    li
    span.ant-menu-title-content {
    opacity: 1 !important;
  }
  .mobile-menu.ant-layout-sider-collapsed.main-left-navigation.true {
    width: auto !important;
    /* padding: auto !important; */
    flex: 0 0 80px !important;
    min-width: unset !important;
    visibility: visible !important;
    max-width: unset !important;
  }
  .profile .gallery .gallery-img-wrapper img {
    width: 100% !important;
  }
  h1.show-on-mobile {
    padding-right: 10% !important;
  }
}

@media only screen and (min-width: 992px) {
  .hide-on-mobile {
    display: flex !important;
  }
  .show-on-mobile {
    display: none !important;
  }
  .main-header {
    display: none;
  }
}
.register-form-fields-wrapper {
  padding-bottom: 100px !important;
}

@media only screen and (max-width: 1200px) {
  .change-password-form-fields-wrapper,
  .forgot-password-form-fields-wrapper,
  .login-form-fields-wrapper,
  .register-form-fields-wrapper {
    padding: 0 15px 100px 15px !important;
    margin-top: 30px;
  }
}

/* @media only screen and (max-width:992px) and (min-width:575px)
{
  .menu-togller {
    margin-left: 8%;
  }
} */

@media only screen and (max-height: 900px) {
  p.versionInfo {
    position: unset !important;
  }
}

